<form (ngSubmit)="onSubmit(form)" #form="ngForm" *transloco="let trl">
  <lsu-layout-feedback type="success" class="mb-2">
    <div>{{ trl("login._sentences.Confirm_password_error_requires_digit") }}</div>
    <div>{{ trl("login._sentences.Confirm_password_error_requires_lower") }}</div>
    <div>{{ trl("login._sentences.Confirm_password_error_requires_non_alphanumeric") }}</div>
    <div>{{ trl("login._sentences.Confirm_password_error_requires_upper") }}</div>
    <div>{{ trl("login._sentences.Confirm_password_error_too_short") }}</div>
  </lsu-layout-feedback>
  <div class="mb-2">
    <mat-form-field appearance="fill" class="w-100" subscriptSizing="dynamic">
      <mat-label>{{ trl("login.your_password") | ucfirst }}</mat-label>
      <input
        lsuDirectivesFocus
        matInput
        type="password"
        name="password"
        ngModel
        #password="ngModel"
        required
        passwordValidator
        [ngModelOptions]="{ updateOn: 'blur' }"
        [code]="code"
        [userId]="userId"
      />

      <mat-error *ngFor="let error of password.errors | keyvalue; let i = index">
        <ng-container *ngIf="error.key === 'required'">
          {{ trl("common._sentences.Required_field", { field: trl("login.password") | ucfirst }) }}
        </ng-container>
        <ng-container *ngIf="error.key !== 'required'">
          {{ error.value }}
        </ng-container>
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>{{ trl("login.confirm_password") }}</mat-label>
      <input matInput type="password" name="confirmPassword" #confirmPassword="ngModel" ngModel required />
      <mat-error *ngIf="confirmPassword.hasError('mismatchedPasswords')"> {{ trl("login._sentences.Passwords_do_not_match") }}</mat-error>
      <mat-error *ngIf="confirmPassword.hasError('required')">
        {{ trl("common._sentences.Required_field", { field: trl("login.confirm_password") | ucfirst }) | ucfirst }}
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="showTwoFa">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>{{ trl("login.two_factor_auth_code") | ucfirst }}</mat-label>
      <input matInput required [(ngModel)]="twoFaCode" name="twoFactorCode" #twoFactorCodeModel="ngModel" autocomplete="new-password" required />
      <mat-hint>{{ trl("login._sentences.Open_auth_app_add_code") }}</mat-hint>
      <mat-error *ngIf="twoFactorCodeModel.hasError('required')">
        {{ trl("common._sentences.Invalid_field", { field: trl("login.two_factor_auth_code") | ucfirst }) | ucfirst }}
      </mat-error>
    </mat-form-field>
  </div>

  <button color="primary" mat-raised-button>{{ buttonText }}</button>
</form>
