<div class="container" *transloco="let trl">
  <div class="row">
    <div class="col-6 mx-auto">
      <mat-card>
        <mat-card-header>
          <mat-card-title size="1">{{ trl("login._sentences.Forgot_username_password") }}</mat-card-title>
          <mat-card-subtitle class="mb-4">{{ trl("login._sentences.Create_new_password") }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <lsf-auth-set-password
            (formOk)="reset($event)"
            buttonText="{{ trl('common._actions.recover') | ucfirst }}"
            [showTwoFa]="tfa"
          ></lsf-auth-set-password>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
