<a
  mat-list-item
  class="px-2"
  (click)="onItemClick($event, item)"
  [routerLink]="item.routerLink"
  [matTooltip]="iconOnly ? item.name : ''"
  [routerLinkActiveOptions]="item.linkActiveOptions || { exact: true }"
  [disabled]="!!item.disabled"
  routerLinkActive="is-active"
>
  <div class="w-100 d-flex justify-content-between">
    <span class="d-flex align-items-center" [ngStyle]="{ 'padding-left': depth * 12 + 'px' }">
      <mat-icon class="me-1">{{ item.icon }}</mat-icon>
      <span class="mat-line" *ngIf="!iconOnly">{{ item.name }}</span>
    </span>
    <span *ngIf="children && children.length && !iconOnly">
      <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"> expand_more </mat-icon>
    </span>
  </div>
</a>

<div *ngIf="expanded && children">
  <lsu-menu-list-item *ngFor="let child of children" [item]="child" [iconOnly]="iconOnly" [depth]="depth + 1"> </lsu-menu-list-item>
</div>
