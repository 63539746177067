import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const credentialsInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  // send cookies. Chrome incognito will always block these cookies!!
  const withCredentials =
    request.url.includes('Login') ||
    request.url.includes('LogOut') ||
    request.url.includes('RegisterConfirmation') ||
    request.url.includes('RegisterTwoFa') ||
    request.url.includes('ResetPasswordConfirmation');
  return withCredentials ? next(request.clone({ withCredentials: true })) : next(request);
};
