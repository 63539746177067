<ng-container *transloco="let trl">
  <lsu-layout-feedback *ngIf="error" type="danger" icon="error">{{ trl(error) }}</lsu-layout-feedback>
  <mat-card class="p-2">
    <h1 class="my-2 d-flex justify-content-center">{{ trl("login.login") | ucfirst }}</h1>
    <div class="row">
      <div class="col-12 col-lg-6 mb-2 mb-lg-0">
        <mat-divider class="mt-2 d-lg-none d-block"></mat-divider>
        <div class="d-flex justify-content-between h-100">
          <lsf-auth-normal-external [prompt]="!!error" class="d-block h-100"></lsf-auth-normal-external>
          <mat-divider vertical class="ms-2 d-none d-lg-block"></mat-divider>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex justify-content-between">
          <lsf-auth-normal-internal></lsf-auth-normal-internal>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
