import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { AppInsightsService, AppSettingsService } from '@shared/util/infrastructure';
import { OidcSecurityService, StsConfigLoader, authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { authFactory, configureAuth } from './core/auth-config';
import { credentialsInterceptor } from './core/credentials.interceptor';

export const authConfig: Provider | EnvironmentProviders = [
  provideAuth({
    loader: {
      provide: StsConfigLoader,
      useFactory: configureAuth,
      deps: [AppSettingsService],
    },
  }),
  provideHttpClient(withInterceptors([credentialsInterceptor, authInterceptor()])),
  {
    provide: APP_INITIALIZER,
    useFactory: authFactory,
    deps: [OidcSecurityService, AppSettingsService, AppInsightsService],
    multi: true,
  },
];
