<div *transloco="let trl">
  <mat-card-header class="mb-3">
    <mat-card-title>{{ trl("login.microsoft_login_account") | ucfirst }}</mat-card-title>
    <mat-card-subtitle>{{ subTitle }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-actions class="d-flex">
    <img class="d-block" width="35" src="./assets/auth/ms-logo.png" alt="{{ trl('login.microsoft_logo') | ucfirst }}" />
    <button color="primary" mat-raised-button (click)="startMicrosoftProvider()" [id]="'loginWithMicrosoftButton'">
      {{ buttonText | ucfirst }} {{ trl("login.with_your_microsoft_account") }}
    </button>
  </mat-card-actions>
</div>
