import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@shared/util/user';
import { loginUrl, storeCurrentUrl } from './auth-config';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard {
  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.userService.currentOrImpersonateUser.isAuthenticated) {
      return true;
    }
    storeCurrentUrl(state.url);
    return this.router.parseUrl(loginUrl);
  }

  canActivateChild(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(activatedRouteSnapshot, state);
  }
}
