<div class="position-relative z-2">
  <mat-toolbar [color]="backgroundColor" class="align-items-center mat-elevation-z4">
    <ng-content></ng-content>
    <span [innerHTML]="headerTitle"></span>
    <span class="d-flex ms-auto align-items-center">
      <div class="mat-subtitle-2" [ngClass]="{ 'me-1': dropDownItems }">
        <ng-content select="lsu-layout-header-custom"></ng-content>
      </div>
      <ng-template [ngIf]="dropDownItems">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>{{ dropDownMenu!.icon }}</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let item of dropDownItems">
            <button *ngIf="!isDivider(item)" mat-menu-item (click)="itemClick($event, item)">
              <mat-icon>{{ item.icon }}</mat-icon>
              <span>{{ item.name }}</span>
            </button>
            <mat-divider *ngIf="isDivider(item)" class="my-2"></mat-divider>
          </ng-container>
        </mat-menu>
      </ng-template>
    </span>
  </mat-toolbar>
</div>
