import { Route, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { EmailConfirmedGuard } from './core/email-confirmed.guard';
import { LoginGuard } from './core/login.guard';
import { LogoutGuard } from './core/logout.guard';
import { PreTokenFetchGuard } from './core/pre-token-fetch.guard';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { DummyComponent } from './pre-token-fetch/pre-token-fetch.component';
import { ResetPasswordRequestComponent } from './reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const children: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'pre-token-fetch', component: DummyComponent, canActivate: [PreTokenFetchGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [LogoutGuard] },
  { path: 'post-logout', component: LogoutComponent, canActivate: [LogoutGuard] },
  { path: 'reset-password-request', component: ResetPasswordRequestComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'change-email-confirmed', component: DummyComponent, canActivate: [EmailConfirmedGuard] },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
];
export const ROUTES = [
  {
    path: '',
    component: AuthComponent,
    children: children,
  },
] satisfies Route[];
