import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { CurrentUserClient } from '@shared/data-access/common';
import { DialogAlertComponent } from '@shared/ui/dialog';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmailConfirmedGuard {
  constructor(
    private dialog: MatDialog,
    private currentUserClient: CurrentUserClient,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // This is the last step of the email change process.
    // Redirect to the root and try to confirm the email change.
    // Always return false.
    const userId = route.queryParams['userid'];
    const code = route.queryParams['c'];
    const emailEncoded = route.queryParams['email'];
    const email = window.atob(emailEncoded);

    await firstValueFrom(this.currentUserClient.changeEmailConfirmation(userId, email, code));
    const data = { message: this.translocoService.translate('login._sentences.Email_stored') };
    await DialogAlertComponent.open(this.dialog, { width: '300px', data });

    this.router.navigateByUrl('/');
    return false;
  }
}
