<div class="vh-100 d-flex flex-column">
  <lsu-layout-header [headerTitle]="headerTitle" [dropDownMenu]="headerDropDownMenu" [backgroundColor]="backgroundColor">
    <button mat-icon-button class="ms-n1" (click)="onMenuClick()" *ngIf="sideNav.length">
      <mat-icon class="menu-icon">menu</mat-icon>
    </button>
    <lsu-layout-header-custom>
      <ng-content select="lsu-layout-header-custom"></ng-content>
    </lsu-layout-header-custom>
  </lsu-layout-header>

  <mat-sidenav-container [autosize]="true" class="h-100">
    <mat-sidenav *ngIf="sideNav.length" [mode]="config.mode" opened class="scrollbar-thin" [ngStyle]="{ minWidth: minWidth }">
      <div class="scrollable-content d-flex flex-column">
        <mat-nav-list>
          <lsu-menu-list-item *ngFor="let nav of sideNav" [item]="nav" [iconOnly]="!expanded"></lsu-menu-list-item>
        </mat-nav-list>
        <div *ngIf="expanded" class="mt-auto" [ngStyle]="{ maxWidth: serverVersionInfoMaxWidth }">
          <ng-content select="lsu-layout-sidenav-navbar-bottom"></ng-content>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <main role="main" tabindex="0">
        <div class="p-2">
          <ng-content></ng-content>
        </div>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
