import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../ui/layout/header/header.component';

@Component({
  selector: 'lsf-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderComponent, RouterOutlet],
})
export class AuthComponent implements OnInit {
  headerTitle = '';
  headerColor = 'primary';

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.headerTitle = this.activatedRoute.snapshot.data['title'] ?? '';
    this.headerColor = this.activatedRoute.snapshot.data['headerColor'] ?? 'primary';
  }
}
