<ng-container *transloco="let trl">
  <lsu-layout-feedback *ngIf="error" type="danger" icon="error">{{ trl(error) }}</lsu-layout-feedback>
  <mat-card class="p-2">
    <ng-container *ngIf="!data">
      <h1 class="mb-2 text-center">{{ trl("login.confirm_account") | ucfirst }}</h1>
      <div class="mat-subheading-1 text-center mb-4">
        <div class="mb-1">{{ trl("login._sentences.Confirm_account_text_1_1") }}</div>
        {{ trl("login._sentences.Confirm_account_text_1_2") }}
        <a routerLink (click)="navigateToLogin()">{{ trl("login._sentences.Confirm_account_text_link") }}</a>
        {{ trl("login._sentences.Confirm_account_text_2") }}
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 mb-2 mb-lg-0">
          <mat-divider class="mt-2 d-lg-none d-block"></mat-divider>
          <div class="d-flex justify-content-between h-100">
            <lsf-auth-confirm-external [prompt]="!!error" class="d-block h-100"></lsf-auth-confirm-external>
            <mat-divider vertical class="ms-2 d-none d-lg-block"></mat-divider>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="d-flex justify-content-between">
            <lsf-auth-confirm-internal (registrationDone)="registrationDone($event)"></lsf-auth-confirm-internal>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="data !== null">
      <lsf-auth-confirm-internal-qr-step [data]="data!"></lsf-auth-confirm-internal-qr-step>
    </ng-container>
  </mat-card>
</ng-container>
