import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { safeSessionStorage } from '@shared/util/code';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { emailParameterConfirmationCode, emailParameterUserId } from '../login/session-storage';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  // the loginGuard will ensure that the loginProcess is started (authorize)
  // And can set session variable:
  // error: Last login error reason
  // emailParameter_userId: userId used in confirmation email
  // emailParameter_confirmationCode: code used in confirmation email
  constructor(private oidcSecurityService: OidcSecurityService) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): boolean {
    // Check if we already on the Idp-loginPage, if not start the authorize proces which redirect the client to the Idp loginPage.
    // We know that authorize process is started when there is a return url
    // The Idp will send the error to the login page. We save it here, start the authorize process again and show and clean it on the loginPage.
    const params = activatedRoute.queryParams;
    this.backupParameter(params['userid'], emailParameterUserId);
    this.backupParameter(params['c'], emailParameterConfirmationCode);
    this.backupParameter(params['ReturnUrl'], 'idpAuthorizeCallback');
    // the last error should contain a message why the last login failed.
    const lastError = safeSessionStorage.getItem('lastError');
    // the last error should contain a message why the last login failed. (authorize process not restarted) or none (authorize process is restarted)
    const error: string = params['errorId'];
    if (lastError) {
      safeSessionStorage.setItem('error', error ? 'initCodeFlow returned an error (Configuration error, see server debugLogs)' : lastError);
      safeSessionStorage.removeItem('lastError');
      return true;
    }
    const retry = safeSessionStorage.getItem('retry');
    if (error) {
      // the Idp-Login page generated an error. Save the error and start the authorize proces
      if (error.startsWith('An error was encountered while handling the remote login.') && !retry) {
        safeSessionStorage.setItem('retry', '1');
        return true;
      }
      safeSessionStorage.setItem('lastError', error);
      this.oidcSecurityService.authorize();
      return false;
    }

    if (!params['ReturnUrl']) {
      // Find the Idp server and start the authorize process
      this.oidcSecurityService.authorize();
      return false;
    }

    // The authorization process is started without errors
    return true;
  }

  canActivateChild(next: ActivatedRouteSnapshot): boolean {
    return this.canActivate(next);
  }

  private backupParameter(parameter: string, storage: string): void {
    if (parameter) {
      safeSessionStorage.setItem(storage, parameter);
    }
  }
}
