import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { CloseOnEscapeDirective } from '@shared/ui/dialog';
import { NgStringPipesModule } from 'ngx-pipes';

@Component({
  selector: 'lsf-auth-dialog-authenticator-info',
  templateUrl: './dialog-authenticator-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, MatCardModule, CloseOnEscapeDirective, MatDialogModule, MatButtonModule, NgStringPipesModule],
})
export class DialogAuthenticatorInfoComponent {}
