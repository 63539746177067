import { CdkPortalOutlet, CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ComponentRef, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { getEnvironment } from '@shared/util/infrastructure';
import { NgStringPipesModule } from 'ngx-pipes';
import { MicrosoftLoginComponent } from '../../microsoft-login/microsoft-login.component';

@Component({
  selector: 'lsf-auth-confirm-external',
  templateUrl: './confirm-external.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, MatCardModule, MatButtonModule, MicrosoftLoginComponent, NgStringPipesModule, NgIf, CdkPortalOutlet],
})
export class ConfirmExternalComponent implements OnInit {
  @Input() prompt = false;
  otherProvider?: ComponentPortal<unknown>;
  constructor(private activatedRoute: ActivatedRoute) {}

  onComponentRendering(evt: CdkPortalOutletAttachedRef) {
    const compRef = evt as ComponentRef<unknown>;
    const compInstance = compRef.instance as { prompt: boolean };
    compInstance.prompt = this.prompt;
  }

  ngOnInit() {
    // TODO remove later: this is to assure that the new provider is not going to production by accident
    if (this.activatedRoute.snapshot.data['otherProvider'] && getEnvironment(window.location) !== 'production') {
      this.otherProvider = new ComponentPortal(this.activatedRoute.snapshot.data['otherProvider']);
    }
  }
}
