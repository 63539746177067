import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { CurrentUserClient } from '@shared/data-access/common';
import { ucFirst } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';
import { SetPasswordComponent } from '../set-password/set-password.component';

@Component({
  selector: 'lsf-auth-reset-password',
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, MatCardModule, SetPasswordComponent, NgStringPipesModule],
})
export class ResetPasswordComponent implements OnInit {
  code: string;
  userId: string;
  tfa!: boolean;

  constructor(
    private currentUserClient: CurrentUserClient,
    private title: Title,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,

    @Inject(DOCUMENT) private document: Document,
  ) {
    this.code = this.route.snapshot.queryParams['c'];
    this.tfa = this.route.snapshot.data['tfa'] ?? true;
    this.userId = this.route.snapshot.queryParams['userid'];
  }

  ngOnInit() {
    this.title.setTitle(this.translocoService.translate('login._sentences.Recover_password'));
  }

  async reset(model: { password: string; twoFaCode: string }) {
    await this.resetPassword(this.code, this.userId, model.password, model.twoFaCode);
  }

  private async resetPassword(code: string, userId: string, password: string, twoFaCode: string) {
    const loginUrl = `${this.document.baseURI}auth/login`;
    twoFaCode = !twoFaCode ? '-' : twoFaCode;
    await firstValueFrom(this.currentUserClient.resetPasswordConfirmation({ userId, password, code, twoFaCode, loginUrl }));

    const snackbar = this.snackBar.open(ucFirst(this.translocoService.translate('login._sentences.Password_changed')));
    await firstValueFrom(snackbar.afterDismissed());
    window.location.href = loginUrl; // The login url will redirect the user to the correct page
  }
}
