<ng-container *transloco="let trl">
  <mat-card-header>
    <mat-card-title>{{ trl("login.username_and_password") | ucfirst }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form (ngSubmit)="login(form)" #form="ngForm">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>{{ trl("login.E-mail") }} </mat-label>
        <input matInput email="normal" [(ngModel)]="username" #email="ngModel" required name="username" />
        <mat-error *ngIf="email.hasError('required')"
          >{{ trl("common._sentences.Required_field", { field: trl("login.E-mail") }) | ucfirst }}
        </mat-error>
        <mat-error *ngIf="email.hasError('email')">{{ trl("common._sentences.Invalid_field", { field: trl("login.E-mail") }) | ucfirst }} </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>{{ trl("login.password") | ucfirst }}</mat-label>
        <input matInput required type="password" [(ngModel)]="password" name="password" />
        <mat-error *ngIf="email.hasError('required')">
          {{ trl("common._sentences.Invalid_field", { field: trl("login.password") | ucfirst }) | ucfirst }}</mat-error
        >
      </mat-form-field>
      <a mat-button routerLink="/auth/reset-password-request" class="d-inline-block mb-4">{{ trl("login._sentences.Forgot_username_password") }}</a>

      <ng-container *ngIf="tfa">
        <div class="mb-2">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ trl("login.two_factor_auth_code") | ucfirst }}</mat-label>
            <input
              matInput
              pattern="[0-9]*"
              required
              [(ngModel)]="twoFactorCode"
              name="twoFactorCode"
              #twoFactorCodeModel="ngModel"
              autocomplete="new-password"
            />
            <mat-hint>{{ trl("login._sentences.Open_auth_app_add_code") | ucfirst }}</mat-hint>
            <mat-error *ngIf="twoFactorCodeModel.hasError('required')"
              >{{ trl("common._sentences.Required_field", { field: trl("login.two_factor_auth_code") | ucfirst }) | ucfirst }}
            </mat-error>
            <mat-error *ngIf="twoFactorCodeModel.hasError('pattern')">
              {{ trl("common._sentences.Invalid_field", { field: trl("login.two_factor_auth_code") | ucfirst }) | ucfirst }}
            </mat-error>
          </mat-form-field>
        </div>
        <lsu-layout-feedback icon="info">
          <p>
            {{ trl("login._sentences.New_mobile_not_in_app") }}
            <a mat-button routerLink="." (click)="onOpenDialog()" class="d-inline-block p-0">{{ trl("login._sentences.Tips_tricks") }}</a>
          </p>
        </lsu-layout-feedback>
      </ng-container>
      <button mat-raised-button color="primary" class="mt-4" [disabled]="!form.valid && tfa">{{ trl("login.login") | ucfirst }}</button>
    </form>
  </mat-card-content>
</ng-container>
