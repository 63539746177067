import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ImpersonateService, UserService } from '@shared/util/user';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LogoutGuard {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private document: Document,
    private impersonateService: ImpersonateService,
    private userService: UserService,
  ) {}

  async canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // If we are on a  tools site. The first thing is to logoff from our IDP on the tool site.
    // After a tools site logoff the user is redirected to the portal site. Also on the portal site the user is logout
    // logoff is a redirect and will redirect to the IDP-post-logout (reload app)
    // After logoff is done on the our IDP. Logoff must be done from any upstream IDPs
    if (await firstValueFrom(this.oidcSecurityService.getAccessToken())) {
      if (this.userService.currentOrImpersonateUser.isImpersonateUser) {
        await this.impersonateService.undoImpersonate();
      }
      await firstValueFrom(this.oidcSecurityService.logoff()); // redirect to Idp. Idp will callback post-logout
      return false;
    }
    if (state.url.indexOf('post-logout') !== -1) {
      // On postLogout remove auth cookie and logout by upstream providers.
      const uri = encodeURIComponent(`${this.document.baseURI}auth/logout`);
      const idp = (await firstValueFrom(this.oidcSecurityService.getConfiguration())).authority;
      window.location.href = idp + `/LogOut?returnUrl=${uri}`;
      return false;
    }
    return true; // Upstream IDP will callback our IDP and our IDP will call auth/logout
  }
}
