import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { InternalAuthenticationClient } from '@shared/data-access/common';
import { safeSessionStorage } from '@shared/util/code';
import { NgStringPipesModule } from 'ngx-pipes';
import { firstValueFrom } from 'rxjs';
import { SetPasswordComponent } from '../../../set-password/set-password.component';
import { emailParameterConfirmationCode, emailParameterUserId } from '../../session-storage';

@Component({
  selector: 'lsf-auth-confirm-internal',
  templateUrl: './confirm-internal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, MatCardModule, SetPasswordComponent, NgStringPipesModule],
})
export class ConfirmInternalComponent {
  code: string;
  userId: string;

  @Output() registrationDone = new EventEmitter<{ userId: string; qrLink: string }>();

  constructor(
    private internalAuthenticationClient: InternalAuthenticationClient,
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService,
  ) {
    this.code = safeSessionStorage.getItem(emailParameterConfirmationCode)!;
    this.userId = safeSessionStorage.getItem(emailParameterUserId)!;
  }
  async confirm(model: { password: string; twoFaCode: string }) {
    const userId = this.userId;
    const qrLink = await firstValueFrom(this.internalAuthenticationClient.registerConfirmation(userId, this.code, model.password));
    if (!qrLink) {
      await this.onSuccess();
      return;
    }
    this.registrationDone.emit({ userId, qrLink });
  }

  private async onSuccess() {
    const url = safeSessionStorage.getItem('idpAuthorizeCallback')!;
    const snackbar = this.snackBar.open(this.translocoService.translate('login._sentences.Account_activated'));
    safeSessionStorage.removeItem(emailParameterConfirmationCode);
    safeSessionStorage.removeItem(emailParameterUserId);
    await firstValueFrom(snackbar.afterDismissed());
    window.location.href = url;
  }
}
