import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { safeSessionStorage } from '@shared/util/code';
import { AppSettingsService } from '@shared/util/infrastructure';
import { NgStringPipesModule } from 'ngx-pipes';
import { emailParameterConfirmationCode, emailParameterUserId } from '../session-storage';

@Component({
  selector: 'lsf-auth-microsoft-login',
  templateUrl: './microsoft-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, MatCardModule, MatButtonModule, NgStringPipesModule],
})
export class MicrosoftLoginComponent implements OnInit {
  @Input() buttonText?: string;
  @Input() subTitle?: string;
  @Input() prompt = false;

  constructor(
    private router: Router,
    private appSettings: AppSettingsService,
  ) {}

  ngOnInit(): void {
    const path = this.router.url.split('?')[0].replace(/^\//, '');
    window.history.replaceState({}, document.title, `${document.baseURI}${path}`);
    const retry = safeSessionStorage.getItem('retry');
    if (retry) {
      this.prompt = true;
      safeSessionStorage.removeItem('retry');
      this.startMicrosoftProvider();
    }
  }

  startMicrosoftProvider(): void {
    const returnUrl = this.createParameter('returnUrl', encodeURIComponent(safeSessionStorage.getItem('idpAuthorizeCallback')!));
    const confirmationCode = this.createParameter('confirmationCode', safeSessionStorage.getItem(emailParameterConfirmationCode));
    const userId = this.createParameter('userId', safeSessionStorage.getItem(emailParameterUserId));
    const prompt = this.createParameter('prompt', this.prompt);
    const idp = this.appSettings.portalApiUrl;
    window.location.href = `${idp}/AzureChallenge?${returnUrl}&${confirmationCode}&${userId}&${prompt}`;
  }

  private createParameter = (name: string, value: string | boolean | null) => (value ? `${name}=${value}` : '');
}
