<div class="container" *transloco="let trl">
  <div class="row justify-content-center">
    <div class="col-6">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ trl("login.logged_out") | ucfirst }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>{{ trl("login._sentences.Logged_out_successfully") }} </mat-card-content>
        <mat-card-actions
          ><a mat-raised-button color="primary" routerLink="/auth/login">{{ trl("login.login") | ucfirst }}</a></mat-card-actions
        >
      </mat-card>
    </div>
  </div>
</div>
