import { SessionStorage } from '@shared/data-access/common';
import { safeSessionStorage } from '@shared/util/code';
import { AppInsightsService, AppSettingsService, throwExpression } from '@shared/util/infrastructure';
import { LoginResponse, OidcSecurityService, OpenIdConfiguration, StsConfigHttpLoader } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export function configureAuth(appSetting: AppSettingsService) {
  return new StsConfigHttpLoader(
    appSetting.initDone$.pipe(
      map(
        () =>
          ({
            redirectUrl: `${document.baseURI}auth/pre-token-fetch`,
            postLogoutRedirectUri: `${document.baseURI}auth/post-logout`,
            eagerLoadAuthWellKnownEndpoints: false,
            clientId: 'Desan',
            scope: 'openid profile email offline_access IT2021API',
            responseType: 'code',
            silentRenew: true,
            useRefreshToken: true,
            maxIdTokenIatOffsetAllowedInSeconds: 600,
            renewTimeBeforeTokenExpiresInSeconds: 30,
            // triggerAuthorizationResultEvent: true, //
            secureRoutes: ['http'],
            authority: appSetting.portalApiUrl,
            authWellknownEndpointUrl: appSetting.portalApiUrl,
            postLoginRoute: getPostLoginUrl(),
            //logLevel: LogLevel.Debug,
          }) as OpenIdConfiguration,
      ),
    ),
  );
}

// APP_INITIALIZER factories must return ()=>Observable or ()=>Promise
export const authFactory = (oidc: OidcSecurityService, appSetting: AppSettingsService): (() => Observable<unknown>) => {
  return () => {
    oidc.userData$.subscribe((x) => {
      AppInsightsService.userId = x.userData?.sub;
      AppInsightsService.tenantId = x.userData?.tenantId;
    });
    return appSetting.initDone$.pipe(
      switchMap(() => checkAuthSafe(oidc)), //Needed to initialize oidc so that status is restored from the session storage 0-Desan
      map(() => true),
    );
  };
};
function checkAuthSafe(oidc: OidcSecurityService): Observable<LoginResponse> {
  // Kwh workaround. Kwh login is with custom token. oidc.checkAuth() will navigate to unauthorized because of using the same parameters (CALLBACK_PARAMS_TO_CHECK=[code,state-id_token,token])
  const notLoggedIn = of({ isAuthenticated: false, accessToken: '', configId: '', idToken: '', userData: null } as LoginResponse);
  if (window.location.href.includes('KwhPortal/fetch-token')) {
    return notLoggedIn;
  }
  try {
    // User with block cookies can't set sessionStorage and throw errors in oidc
    // problem is fixed in oidc, but we are on an old release
    // https://github.com/manfredsteyer/angular-oauth2-oidc/commit/ac931de822501b74e68c603372a5cbe153a8fbb8#diff-685e26c24f3c008856834f8b4a350d5472b6aea01a623c31a8513f6cc599c57f
    window.sessionStorage.getItem('hasAccessToLocaleStorageCheck');
  } catch {
    return notLoggedIn;
  }
  return oidc.checkAuth();
}

function getPostLoginUrl(): string {
  // This config is loaded when starting the Auth.forRoot (App start)
  // We always store the user url when the application start. Except:
  // * the pre-token-fetch
  // * something with the login to prevent looping
  // the postLoginRoute is only used after a successful login.
  const url = document.location.href.replace(document.baseURI.slice(0, -1), '');
  if (url.includes('/pre-token-fetch')) {
    const returnUrl = safeSessionStorage.getItem('userRequestUrl');
    safeSessionStorage.removeItem('userRequestUrl');
    return returnUrl!;
  }
  if (!url.includes('/login') && !url.includes('/auth/')) {
    safeSessionStorage.setItem('userRequestUrl', url);
  }
  return '/'; // this will not be used but we must return some non null value
}
export function storeCurrentUrl(url: string) {
  safeSessionStorage.setItem('userRequestUrl', url);
}
export const loginUrl = '/auth/login';

export function getOidcConfig(): SessionStorage {
  return tryGetOidcConfig() ?? throwExpression('Oidc config not found.');
}

export function tryGetOidcConfig() {
  const key = '0-Desan';
  const value = safeSessionStorage.getItem(key);
  if (value) {
    return { session: { [key]: value } };
  }
  return undefined;
}
