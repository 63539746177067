<div class="container" *transloco="let trl">
  <div class="row">
    <div class="col-6 mx-auto">
      <mat-card>
        <mat-card-header>
          <mat-card-title size="1">{{ trl("login._sentences.Forgot_username_password") }}</mat-card-title>
          <mat-card-subtitle>{{ trl("login._sentences.Enter_email_password") }}</mat-card-subtitle>
        </mat-card-header>
        <form (ngSubmit)="reset(form)" #form="ngForm">
          <mat-card-content>
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ trl("login.E-mail") }}</mat-label>
              <input matInput email="normal" #email="ngModel" [(ngModel)]="confirmation.email" mxRecordValidator name="email" required />
              <mat-error *ngIf="email.hasError('email') && form.submitted">{{
                trl("common._sentences.Invalid_field", { field: trl("login.E-mail") }) | ucfirst
              }}</mat-error>
              <mat-error *ngIf="email.hasError('required') && form.submitted">{{
                trl("common._sentences.Required_field", { field: trl("login.E-mail") }) | ucfirst
              }}</mat-error>
            </mat-form-field>
          </mat-card-content>
          <mat-card-actions>
            <button color="primary" mat-raised-button>{{ trl("common._actions.recover") | ucfirst }}</button>
          </mat-card-actions>
        </form>
      </mat-card>
    </div>
  </div>
</div>
