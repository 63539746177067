<mat-card *transloco="let trl">
  <lsuCloseOnEscape></lsuCloseOnEscape>
  <h1 mat-dialog-title>{{ trl("login.authenticator") | ucfirst }}</h1>
  <mat-dialog-content>
    <h3>{{ trl("login.new_mobile") | ucfirst }}</h3>
    <ul class="mb-2">
      <li>{{ trl("login._sentences.New_mobile_steps_1") }}</li>
      <li>{{ trl("login._sentences.New_mobile_steps_2") }}</li>
      <li>{{ trl("login._sentences.New_mobile_steps_3") }}</li>
      <li>{{ trl("login._sentences.New_mobile_steps_4") }}</li>
      <li>{{ trl("login._sentences.New_mobile_steps_5") }}</li>
      <li>{{ trl("login._sentences.New_mobile_steps_6") }}</li>
    </ul>
    <h3>{{ trl("login._sentences.New_mobile_steps_7") }}</h3>
    <p>{{ trl("login._sentences.New_mobile_steps_8") }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button matDialogClose tabindex="-1">{{ trl("common._actions.close") | ucfirst }}</button>
  </mat-dialog-actions>
</mat-card>
