import { Injectable } from '@angular/core';
import { AppInsightsService } from '@shared/util/infrastructure';

@Injectable({ providedIn: 'root' })
export class PreTokenFetchGuard {
  canActivate(): boolean {
    AppInsightsService.trackTraceWarn('Pretoken fetch is replaced by authFactory in authConfig, which calls the checkAuth-function.');
    return false;
  }
}
