import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { safeSessionStorage } from '@shared/util/code';
import { ConfirmLoginComponent } from './confirm-login/confirm-login.component';
import { NormalLoginComponent } from './normal-login/normal-login.component';
import { emailParameterConfirmationCode, emailParameterUserId } from './session-storage';

@Component({
  selector: 'lsf-auth-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ConfirmLoginComponent, NormalLoginComponent],
})
export class LoginComponent {
  isConfirmLogin: boolean;
  userId: string | null;
  confirmationCode: string | null;

  constructor() {
    this.userId = safeSessionStorage.getItem(emailParameterUserId);
    this.confirmationCode = safeSessionStorage.getItem(emailParameterConfirmationCode);
    this.isConfirmLogin = !!this.userId && !!this.confirmationCode;
  }
}
