<form *transloco="let trl">
  <!-- TODO(mdc-migration-verify): Mat-card-subtitle should be wrapper in a mat-card-header for the styling to be applied. -->
  <mat-card-subtitle class="mb-4">{{ trl("login.scan_qr_code") | ucfirst }}</mat-card-subtitle>
  <div class="d-flex">
    <div class="w-50">
      <div class="row">
        <div class="col-md-12 mb-2 mb-md-0 text-center">
          <qrcode [qrdata]="data.qrLink" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ trl("login.enter_code") | ucfirst }}</mat-label>
            <input matInput type="number" name="code2Fa" [(ngModel)]="qrCode" #qrCodeRef="ngModel" required lsuDirectivesFocus />
            <mat-error *ngIf="qrCodeRef.hasError('required')">The field '{{ trl("login.enter_code") | ucfirst }}' is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-divider [vertical]="true" class="mx-2"></mat-divider>
    <div class="w-50">
      <p class="mb-2">{{ trl("login._sentences.Download_qr_app") }}</p>
      <div class="paragraph text-center w-100">
        <a target="_blank" tabindex="0" [title]="trl('login._sentences.Qr_google_play_authenticator')" [attr.href]="trl('login.download_qr_google')">
          Android
        </a>
        |
        <a
          target="_blank"
          tabindex="0"
          [title]="trl('login._sentences.Qr_apple_authenticator')"
          href="https://apps.apple.com/us/app/google-authenticator/id388497605"
        >
          iPhone
        </a>
      </div>
    </div>
  </div>
  <mat-card-actions>
    <button color="primary" mat-raised-button (click)="confirmQr()">{{ trl("common._actions.confirm") | ucfirst }}</button>
  </mat-card-actions>
</form>
